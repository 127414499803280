// import { StyleSheet, Text, ScrollView, View, Dimensions, TextInput, Image, Alert, WebView, Linking } from 'react-native';
import { StyleSheet, Text, ScrollView, View, Dimensions, TextInput, Image, Alert, WebView } from 'react-native';
import * as Linking from 'expo-linking'
import React, { useEffect, useState, useRef } from "react";
import { Input } from 'react-native-elements'
import { heightPercentageToDP } from 'react-native-responsive-screen';

import { webStyles, mobileStyles, mobileContainerStyle, webContainerStyle, webInputContainerStyle, mobileInputContainerStyle, buttonColor, buttonShadowColor } from '../styles/Standard/screens/Login.styles'; //rename
import { StyledButton } from '../components/common/StyledButton';
import { ErrorDisplay } from '../components/common/ErrorDisplay';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { playSound } from '../utils/playSound';
import { clearGlobalState } from '../utils/clearGlobalState';
import { loginSuccessSound } from '../assets/sounds'
import { getElement, Firebase } from '../firebase.config';
import { logos } from '../assets/images';

import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import globalState from "../assets/GlobalState";

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export function Login({ navigation }, props) {
    const [isEmailInputSelected, setIsEmailInputSelected] = useState(false)
    const [isPasswordInputSelected, setIsPasswordInputSelected] = useState(false);

    const [typedEmailAddress, setTypedEmailAddress] = useState("");
    const [typedPassword, setTypedPassword] = useState("");

    const [errorText, setErrorText] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [isFirstLoginLoad, setIsFirstLoginLoad] = useState(true)
    const [isForgotPasswordMode, setIsForgotPasswordMode] = useState(false)
    const [companyImageUrl, setCompanyImageUrl] = useState(undefined)
    const [signedInUser, setSignedInUser] = useState(null);
	const [isLoading, setIsLoading] = useState(false)

    const userDocsResult = useRef(null);

    var styles;
    var containerStyle;
    var inputContainerStyle;

    { // keep globalState scope local
        const globalState = require('../assets/GlobalState')
        if (companyImageUrl == undefined && globalState.companyLogoUrl != undefined && globalState.companyLogoUrl.length > 1) {
            // companyImageUrl = globalState.companyLogoUrl
            setCompanyImageUrl(globalState.companyLogoUrl)
        }
    }

    useEffect(() => {

        let getAllUserDocs = Firebase.functions().httpsCallable('getAllUserDocs');
        let params = {
            authState: "preLogin"
        };
        
        getAllUserDocs(params)
            .then(response => {
                
                if (!response?.data) {
                    userDocsResult.current = {
                        hasErred: true,
                        error: "Failed to find linked accounts: no response"
                    };

                    return;
                }

                userDocsResult.current = response.data;

            });

    }, []);

    function getUsers(userId, count) {

        if (userDocsResult.current === null) {

            if (count > 120) {
                setIsLoading(false);
                setErrorText("Failed to find linked accounts: request timout");
                return;
            }

            // Recursively call function until the backend function for getting the user docs is done
            setTimeout(() => {
                getUsers(userId, ++count);
            }, 1000);

            return;

        }

        if (userDocsResult.current.hasErred === true) {
            
            setIsLoading(false);
            setErrorText(userDocsResult.current.error);
            return;

        } else if (userDocsResult.current.success !== true) {

            setIsLoading(false);
            setErrorText("Failed to find linked accounts: please contact support");
            return;

        }

        let users = userDocsResult.current.allUsers;

        if (users.length <= 0) {
                
            setErrorText("Account is not linked to any companies. Please contact support")

        } else {
            
            users = users.filter(u => u.company && u.department && u.id === userId);
            //take users[0].gameRetryOverride and store it in globalState

            if (users.length <= 0) {

                setErrorText("Profile is not linked to a specific company or department. It is perhaps is an admin or manager account that cannot be assigned to any game");

            } else if (users.length > 1) {
                playSound(loginSuccessSound);
                navigation.navigate("company_selection", { users });     
            } else {

                loadUserData(users[0]);

            }
        }

        setIsLoading(false);
        
    }

    function getAllUsers(userId) {

        let users = [];
        let isManagersDone = false;
        let isCompaniesDone = false;

        const resolve = () => {

            if (!isManagersDone || !isCompaniesDone) return;

            if (users.length <= 0) {
                
                setErrorText("Account is not linked to any companies. Please contact support")

            } else {
                
                users = users.filter(u => u.company && u.department)
                
                if (users.length <= 0) {

                    setErrorText("Profile is not linked to a specific company or department. It is perhaps is an admin or manager account that cannot be assigned to any game");

                } else if (users.length > 1) {

                    playSound(loginSuccessSound);
                    navigation.navigate("company_selection", { users });     
    
                } else {
    
                    loadUserData(users[0]);
    
                }
            }

            setIsLoading(false);

        };

        getElement([ "Managers" ]).get()
        .then(userCollection => {
            
            userCollection?.docs?.filter(d => d.id === userId)?.forEach((userDoc, u) => {
            
                let userData = userDoc.data();
                let user = userData?.userInfo;

                users.push({
                    id: userDoc.id,
                    department: user?.department,
                    company: user?.company,
                    email: user?.email,
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    role: user?.role,
                    disabled: user?.disabled
                });
                
            });
            
            isManagersDone = true;
            resolve();

        })
        .catch(() => {
            
            isManagersDone = true;
            resolve();

        });

        getElement([ "Companies" ]).get()
        .then(companyCollection => {

            if (!companyCollection?.docs?.length > 0) {
                isCompaniesDone = true;
                resolve();
                return;
            }
            
            companyCollection.docs.forEach((companyDoc, c) => {

                let company = companyDoc.data();

                getElement([ "Companies", companyDoc?.id, "Users"]).get()
                .then(userCollection => {
                    
                    userCollection?.docs?.filter(d => d.id === userId)?.forEach((userDoc) => {

                        let userData = userDoc.data();
                        let user = userData?.userInfo;
        
                        users.push({
                            id: userDoc.id,
                            department: user?.department,
                            company: user?.company,
                            companyName: company?.name,
                            companyLogo: company.logoUrl,
                            email: user?.email,
                            firstName: user?.firstName,
                            lastName: user?.lastName,
                            role: user?.role,
                            disabled: user?.disabled
                        });
                        
                    });
                    
                    if (c >= companyCollection.docs.length - 1) {
                        isCompaniesDone = true;
                        resolve();
                    }

                })
                .catch(() => {
                    
                    if (c >= companyCollection.docs.length - 1) {
                        isCompaniesDone = true;
                        resolve();
                    }

                });

            });

        })
        .catch(() => {

            isCompaniesDone = true;
            resolve();

        });

    }

    if (companyImageUrl == undefined) {
        Linking.getInitialURL().then((url) => {
            const baseUrlEndPosition = url.indexOf(".co.za") + 6
            var searchUrl = url.substring(0, baseUrlEndPosition)
            var path = ['Companies']
            getElement(path)
                .where("url", "==", searchUrl)
                .get()
                .then(function (response) {
                    //handle if single too
                    const doc = response.docs[0]
                    if (response.docs.length < 1) {
                        setCompanyImageUrl("")
                    }
                    if (doc != undefined && doc.data() != undefined) {
                        const companyData = doc.data()

                        if (companyData.logoUrl != undefined && companyData.logoUrl.length > 1) {
                            setCompanyImageUrl(companyData.logoUrl)

                        } else {
                            console.log("company logo url not defined")
                        }
                    }
                })

            // if none Found, search with adding s: https
        });
    }

    async function checkResetPassword() {
        var checkIfUserPasswordUnset = Firebase.functions().httpsCallable('checkIfUserPasswordUnset'); //can this be executed without being logged in ?
        checkIfUserPasswordUnset({
            email: typedEmailAddress
        })
            .then(function (response) {
                if (response == true || response.data == true) {
                    Firebase.auth().sendPasswordResetEmail(typedEmailAddress).then(function() {
                        setErrorText("Your password has not been reset yet. " +
                        "A reset email has been sent to your email address. " +
                        "Reset your email within 24 hours. Disregard this if you know your password")
                      }).catch(function(error) {
                        setErrorText("password reset email error")
                      });
                    
                }
            })
            .catch(error => {
                setErrorText("checkIfUserPasswordUnset error, ", error)
            })
    }

    function cleanLogin() {
        clearGlobalState()
        Firebase.auth().signOut().then(function () {
        }).catch(function (error) {
            setErrorText(error.message)
        });
        setIsFirstLoginLoad(false)
    }

    if (isFirstLoginLoad == true) {
        cleanLogin()
    }

    var isWeb = false
    if (Constants.platform != 'android' && Constants.platform != 'ios' && windowWidth / windowHeight > 0.9) {
        styles = webStyles
        containerStyle = webContainerStyle
        inputContainerStyle = webInputContainerStyle
        isWeb = true
    } else {
        styles = mobileStyles
        containerStyle = mobileContainerStyle
        inputContainerStyle = mobileInputContainerStyle
    }

    function loadUserData(user) {
        const globalState = require('../assets/GlobalState')
        
        if (globalState.DBPath.companyId == undefined || globalState.DBPath.departmentId == undefined) {
            try {

                let companyId = user.company;
                let departmentId = user.department;
                
                if (!companyId || !departmentId) {
                    setErrorText("Profile is not linked to a specific company or department. It is perhaps is an admin or manager account that cannot be assigned to any game");
                    return;
                }
                
                globalState.DBPath.companyId = companyId;
                globalState.companyIdForLoginImage = companyId;
                globalState.DBPath.departmentId = departmentId;
                globalState.DBPath.userId = user.id;
                globalState.userProfile = user;
                globalState.userGameRetryOverride = user.gameRetryOverride;

                AsyncStorage.setItem('globalState', JSON.stringify(globalState));

                playSound(loginSuccessSound);
                navigation.replace('dashboard');
                
            } catch (e) {
                setErrorText(e)
            }
        }
    }

    function signIn(email, password) {
        
        setIsLoading(true)
        clearGlobalState()
        setErrorText("")

        Firebase.auth()
            .signInWithEmailAndPassword(email.trim(), password)
            .then((response) => {

                if (response.user != undefined) {
                    setSignedInUser(response.user);
                    //getAllUsers(response.user.uid);
                    getUsers(response.user.uid, 0);
                }

            }).catch(function (error) {

                setIsLoading(false);
                setErrorText(error.message)

                checkResetPassword()

                const globalState = require('../assets/GlobalState')
                if (globalState.DBPath == undefined) {
                    return
                }
            });
    }

    function toggleForgotPasswordMode() {
        setIsForgotPasswordMode(!isForgotPasswordMode)
    }

    function forgotPassword() {
        // https://stackoverflow.com/questions/54515444/how-to-reset-firebase-auth-password-in-react-native
        if (typedEmailAddress.length < 3) {
            alert('Please enter email for forgot password.')
            setErrorText('Please enter email for forgot password.')
        }
        Firebase.auth().sendPasswordResetEmail(typedEmailAddress)
            .then(function (user) {
                alert('Please check your email...')
                setErrorText('')
                setIsForgotPasswordMode(false)
            }).catch(function (error) {
                setErrorText(error.message)
            })
    }

    var emailContainerStyleDefinition = {
        ...containerStyle
    }

    var inputContainerStyleDefinition = {
        ...inputContainerStyle
    }

    var passwordContainerStyleDefiniton = {
        ...containerStyle
    }

    const emailContainerStyle = StyleSheet.create({
        containerStyle: emailContainerStyleDefinition
    });

    const emailInputContainerStyle = StyleSheet.create({
        inputContainerStyle: inputContainerStyleDefinition
    });

    const passwordInputContainerStyle = StyleSheet.create({
        inputContainerStyle: inputContainerStyleDefinition
    });

    const passwordContainerStyle = StyleSheet.create({
        containerStyle: passwordContainerStyleDefiniton
    });

    var emailInputProps = (isEmailInputSelected) ? {
        label: 'Email',
        labelStyle: { opacity: 0, backgroundColor: '#FFF', width: windowWidth * 0.1, marginTop: -10, marginBottom: 0, color: '#FF5454' }
    } : {}

    var passwordInputProps = (isPasswordInputSelected) ? {
        label: 'Password'
    } : {}

    function toggleShowPassword() {
        
        setShowPassword(showPassword ? false : true)
        // , onPress: { toggleShowPassword }
    }

    function renderPasswordInput() {
        if (!isForgotPasswordMode) {
            return (<Input  {...passwordInputProps} secureTextEntry={!showPassword} disabled={isForgotPasswordMode || isLoading}
                placeholder="Password" type="password" onChangeText={password => setTypedPassword(password)}
                // onFocus={() => {}}
                inputStyle={{ fontSize: heightPercentageToDP(3), maxWidth: (isWeb) ? 0.15 * windowWidth : '100%', marginVertical: 0, borderColor: '#FFF', height: (isWeb) ? heightPercentageToDP(6) : '100%' }} rightIcon={{ type: 'AntDesign', name: "visibility", size: heightPercentageToDP(3.8), iconStyle: { opacity: 0.5 }, onPress: toggleShowPassword }}
                rightIconContainerStyle={{ marginHorizontal: (isWeb) ? 0.05 * windowWidth : 0, height: '100%', marginLeft: 0 }}
                containerStyle={passwordContainerStyle.containerStyle} inputContainerStyle={passwordInputContainerStyle.inputContainerStyle} />
            )
        } else {
            return null
        }
    }

    return (
        <ScrollView keyboardShouldPersistTaps='handled' style={styles.dashboardContainer} contentContainerStyle={{ height: windowHeight }}>
            <View style={styles.logoContainer}>
                {/* <Text style={styles.logo}>GAMEON!</Text> */}
                <Image
                    style={styles.logo}
                    activeOpacity={0.9}
                    resizeMode="contain"
                    source={companyImageUrl != undefined && companyImageUrl.length > 0 ? { uri: companyImageUrl } : logos.gameOn} />
                {/* // source={{ uri: "https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/achievements_images%2F3d054b88-205d-436c-8de0-12a81eb8cc41?alt=media&token=d1caca01-ad9d-43e3-9ed3-b291a26559d8" }} /> */}
            </View>
            {/* for web secure password  <View  accessibilityRole='form' style={styles.inputContainers} >  */}
            <View style={styles.inputContainers} >
                <Input  {...emailInputProps} placeholder="Email" onChangeText={email => setTypedEmailAddress(email)}
                    inputStyle={{ fontSize: heightPercentageToDP(3), height: (isWeb) ? heightPercentageToDP(6) : '100%' }} containerStyle={emailContainerStyle.containerStyle} inputContainerStyle={emailInputContainerStyle.inputContainerStyle} 
                    disabled={isLoading} />
                {renderPasswordInput()}
                {/* inputStyle={{ fontSize: heightPercentageToDP(3) }} containerStyle={emailContainerStyle.containerStyle} inputContainerStyle={{ borderRadius: 0, borderColor: '#FFF', marginHorizontal: windowWidth * 0.01, marginVertical: windowHeight * 0.0, paddingVertical: heightPercentageToDP(0.5) }} /> */}
                {/* <Input  {...emailInputProps} placeholder="Email" containerStyle={{}} inputContainerStyle={{ borderBottomWidth: 0, marginHorizontal: windowWidth * 0.01, marginVertical: windowHeight * 0.05, backgroundColor: '#000' }} /> */}
                {/* <Input  {...passwordInputProps} placeholder="Password" type={showPassword ? "text" : "password"} onChangeText={password => setTypedPassword(password)} inputStyle={{ fontSize: heightPercentageToDP(3) }} rightIcon={{ type: 'AntDesign', name: "visibility", iconStyle: { opacity: 0.5 },  onPress: toggleShowPassword }}  containerStyle={passwordContainerStyle.containerStyle} inputContainerStyle={{ borderBottomWidth: 0, marginHorizontal: windowWidth * 0.01, paddingVertical: heightPercentageToDP(0.5) }} /> */}

            </View>
            <View style={styles.buttonsContainerStyle}>
                <View style={styles.buttonContainerStyle}>
                    <StyledButton text={!isForgotPasswordMode ? 'LOGIN' : 'Send email'} onPressFunction={!isForgotPasswordMode ? signIn : forgotPassword} typedPassword={typedPassword} typedEmailAddress={typedEmailAddress} backgroundColor={buttonColor} shadowColour={buttonShadowColor} isLoading={isLoading} ></StyledButton>
                </View>
                <TouchableOpacity onPress={toggleForgotPasswordMode} disabled={isLoading} >
                    <Text style={styles.forgotPasswordText} >{!isForgotPasswordMode ? "Forgot Password" : "Return"}</Text>
                </TouchableOpacity>
                <ErrorDisplay errorText={errorText} />
            </View>
            <Text style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                opacity: 0.5
            }}>
                1.1.11
			</Text>
        </ScrollView>
    );
}
