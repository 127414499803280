import { Alert, BackHandler, Dimensions, Image, ImageBackground, StyleSheet, Text, TouchableOpacity, View, ScrollView } from "react-native";
import { Button, Icon } from "react-native-elements";
import React, { useEffect, useState } from "react";
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import { WebView } from "react-native-webview";
import { Firebase, getElement } from "../firebase.config";
import { GameButton } from "../components/game/GameButton";
import { CountDownTimer } from "../components/game/CountDownTimer";
import { QuestionAnsweredBanner } from "../components/game/QuestionAnsweredBanner";
import { MessageBanner } from "../components/game/MessageBanner";
import { LeaderboardModal } from "../components/game/LeaderboardModal";
import { LOADING_QUESTION } from "../assets/text/eng";
import { button2Style, button3Style, button4Style, styles } from "../styles/Standard/screens/GameScreen.style.js";
import { backgroundImages } from "../assets/images";
import { StyledButton } from "../components/common/StyledButton";
import { playSound } from "../utils/playSound";
import { answerCorrectSound, incorrectAnswerSound } from "../assets/sounds";
import createCrossword from "../components/game/CreateCrossWord";
import CrosswordPuzzle from "../components/game/CrosswordPuzzle";

export function GameScreen({ navigation }, props) {
	const [crossWordAnswers, setCrossWordAnswers] = useState([]);
	const [crossWordCorrect, setCrossWordCorrect] = useState(false);
	const [crossWordData, setCrossWordData] = useState({ across: {}, down: {} });
	const [crossWordLayoutData, setCrossWordLayoutData] = useState();
	const [isReadyToContinue, setIsReadyToContinue] = useState(false);
	const questionsToAskCount = 5;
	const [questionTime, setQuestionTime] = useState(30);
	const [isInitialised, setIsInitialised] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingNextQuestion, setIsLoadingNextQuestion] = useState(false);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [isQuestionActive, setIsQuestionActive] = useState(false);
	const [currentQuestion, setCurrentQuestion] = useState();
	const [questionKeys, setQuestionKeys] = useState();
	const [questionResult, setQuestionResult] = useState("unanswered");
	const [questionsAnsweredCorrect, setQuestionsAnsweredCorrect] = useState({});
	const [gameInfo, setGameInfo] = useState();
	const [userAnswers, setUserAnswers] = useState([]);
	const [score, setScore] = useState(0);
	const [shouldStopTimer, setShouldStopTimer] = useState(false);
	const [shouldStartTimer, setShouldStartTimer] = useState(false);
	const [triggerRefresh, setTriggerRefresh] = useState(false);
	const [playTime, setPlayTime] = useState(0);
	const [isCheckingActiveGameDetails, setIsCheckingActiveGameDetails] = useState(false);
	const [displayMessageBanner, setDisplayMessageBanner] = useState(false);
	const [bannerMessage, setBannerMessage] = useState("");
	const [buttonStatus, setButtonStatus] = useState(Array(4).fill("unanswered"));
	const [showModal, setShowModal] = useState(false);
	const [imageBackgroundIndex, setImageBackgroundIndex] = useState(Math.floor(Math.random() * (backgroundImages.length - 1)));
	const [matchTermState, setMatchTermState] = useState({
		leftTerms: [],
		rightTerms: [],
		matchedTerms: {
			0: -1,
			1: -1,
			2: -1,
			3: -1,
			4: -1,
		},
		selectedLeftTermIndex: -1,
		selectedRightTermIndex: -1,
	});
	const [bucketState, setBucketState] = useState({
		buckets: {
			left: [],
			right: [],
		},
		selectedItemIndex: -1,
	});
	const [tapsLeft, setTapsLeft] = useState(undefined);
	const [gridData, setGridData] = useState(undefined);
	const [wasGroupSelected, setWasGroupSelected] = useState(undefined);
	const [refreshTimer, setRefreshTimer] = useState(false);
	const [currentQuestionScore, setCurrentQuestionScore] = useState(-1);
	const [maxCurrentQuestionScore, setMaxCurrentQuestionScore] = useState(0);
	const windowWidth = Dimensions.get("window").width;
	const windowHeight = Dimensions.get("window").height;
	let globalState = require("../assets/GlobalState");
	let gameState = require("../assets/GameState");
	const companyName = globalState.DBPath.companyId;
	const departmentId = globalState.DBPath.departmentId;
	const gameId = globalState.DBPath.gameId;
	const categoryId = globalState.DBPath.gameCategoryId;
	const userId = globalState.DBPath.userId;
	const [crossWords, setCrossWords] = useState([{}]);

	//for use With Blank Game
	const blankSpace = "______";
	const [blankedStatement, setBlankedStatement] = useState("");
	const [blankedWords, setBlankedWords] = useState([]);
	const [originalStatement, setOriginalStatement] = useState("");
	const [readyForSubmit, setReadyForSubmit] = useState(false);

	//
	async function locallyCreate(rawData) {
		let gridAndClues = await createCrossword(rawData);
		console.log("CROSSWORDD", gridAndClues.layout.result);
		setCrossWordLayoutData(gridAndClues.layout);
		setCrossWordData(gridAndClues.output);
		return gridAndClues.output;
	}
	//

	function checkInputContext(globalState) {
		const inputs = [globalState.DBPath.companyId, globalState.DBPath.departmentId, globalState.DBPath.gameId, globalState.DBPath.gameCategoryId, globalState.DBPath.userId];
		for (const i in inputs) {
			const currentInput = inputs[i];
			if (currentInput === undefined || currentInput.length < 1) {
				return false;
			}
		}
		return true;
	}
	function getAndSetLocalLeaderboard() {
		if (globalState.DBPath.companyId === undefined || globalState.DBPath.departmentId === undefined || globalState.DBPath.gameId === undefined) {
			navigation.replace("dashboard");
			return;
		}
		const path = ["Companies", globalState.DBPath.companyId, "Departments", globalState.DBPath.departmentId, "Leaderboard", globalState.DBPath.gameId];
		getElement(path)
			.get()
			.then(function (doc) {
				if (doc.exists) {
					let docData = doc.data();
					if (docData === undefined || docData.users === undefined || Object.keys(docData.users).length < 1) {
						console.log("leaderboard empty");
					} else {
						const leaderboardObj = docData.users;
						const leaderboardKeys = Object.keys(leaderboardObj);
						leaderboardKeys.sort();
						const newLeaderboard = [];
						for (var i = 0; i < leaderboardKeys.length; i++) {
							var leaderboardEntry = leaderboardObj[leaderboardKeys[i]];
							newLeaderboard.push(leaderboardEntry);
						}
						gameState.localGameLeaderboard = newLeaderboard;
					}
				} else {
					console.log("Leaderboard Document not found");
				}
			})
			.catch(function (error) {
				console.log("Error getting leaderboard doc", error);
			});
	}
	function onBackPressDisable() {
		return true;
	}
	async function closePage(e) {
		e?.preventDefault();
		let deleteActiveGameLog = Firebase.functions().httpsCallable("deleteActiveGameLog");
		let params = {
			companyId: globalState?.DBPath?.companyId,
			departmentId: globalState?.DBPath?.departmentId,
			gameId: globalState?.DBPath?.gameId,
			userId: globalState?.DBPath?.userId,
		};
		await deleteActiveGameLog(params);
	}
	useEffect(() => {
		setIsLoading(true);
		setIsCheckingActiveGameDetails(true);
		setDisplayMessageBanner(true);
		setBannerMessage("Loading questions...");
		let logActiveGame = Firebase.functions().httpsCallable("logActiveGame");
		let params = {
			companyId: globalState?.DBPath?.companyId,
			departmentId: globalState?.DBPath?.departmentId,
			gameId: globalState?.DBPath?.gameId,
			userId: globalState?.DBPath?.userId,
		};
		logActiveGame(params)
			.then((response) => {
				setIsLoading(false);
				setIsCheckingActiveGameDetails(false);
				if (response?.data?.isAlreadyLogged === true) {
					setBannerMessage("This game is already being played in another instance. This instance will be discarded");
					setTimeout(() => {
						navigation.replace("dashboard");
					}, 3000);
				} else {
					initialise();
					setDisplayMessageBanner(false);
					window.onbeforeunload = closePage;
					navigation.addListener("beforeRemove", (e) => {
						closePage(e);
						navigation.dispatch(e.data.action);
					});
				}
			});
	}, []);

	useEffect(() => {
		if (currentQuestion !== undefined && isQuestionActive === false) {
			setIsQuestionActive(true);
		}
	}, [currentQuestion]);
	useEffect(() => {
		if (isLoadingNextQuestion) {
			setIsLoadingNextQuestion(false);
			console.log("Going Next");
			next();
		} else {
			console.log("Not going next");
		}
	}, [isLoadingNextQuestion]);
	function initialise() {
		getAndSetLocalLeaderboard();
		if (!checkInputContext(globalState)) {
			Alert.alert("Input context varialbe not defined.");
			navigation.replace("dashboard");
			return null;
		}
		BackHandler.addEventListener("hardwareBackPress", onBackPressDisable);
		setCurrentQuestionIndex(0);
		let path;
		if (gameState.gameInfo.maximumGameScore === undefined || gameState.gameInfo.maximumGameScore === 0) {
			//retry if bad response?
			path = ["Companies", globalState.DBPath.companyId, "Departments", globalState.DBPath.departmentId, "Games", globalState.DBPath.gameId];
			getElement(path)
				.get()
				.then(function (doc) {
					console.log("game info doc", doc);
					if (doc.exists) {
						let gameData = doc.data();
						if (gameData !== undefined && gameData.questionTime !== undefined) {
							setQuestionTime(gameData.questionTime);
						}
						if (gameData === undefined || gameData.maximumCorrect === undefined || gameData.maximumCorrect < 1) {
							console.log("old MaximumScore is invalid.");
						} else {
							gameState.gameInfo.maximumGameScore = gameData.maximumCorrect;
						}
					} else {
						Alert.alert("Game Document not found.");
						navigation.replace("dashboard");
					}
				})
				.catch(function (error) {
					console.log("Error getting Game doc", error);
				});
		}
		path = ["Companies", globalState.DBPath.companyId, "Departments", globalState.DBPath.departmentId, "Games", globalState.DBPath.gameId, "Categories", globalState.DBPath.gameCategoryId];
		getElement(path)
			.get()
			.then(async function (doc) {
				if (doc.exists) {
					let catData = doc.data();
					const numQuestions = Object.keys(catData.questions || {}).length;
					console.log("catData", catData);
					if (catData.questions === undefined || numQuestions < 1) {
						setCategoryResults(globalState.DBPath.gameCategoryId, 0);
						nextCategory();
					} else {
						setGameInfo({
							...catData,
							passRate: Number(catData.passRate) > 0 ? Number(catData.passRate) : 50
						});
						if (!gameState.gameInfo.maximumGameScore) gameState.gameInfo.maximumGameScore = Math.min(5, numQuestions);
						const newQuestionKeys = shuffleFisherYates(Object.keys(catData.questions));
						const localCurrentQuestion = catData.questions[newQuestionKeys[0]];
						setQuestionKeys(newQuestionKeys);
						setCurrentQuestion(localCurrentQuestion);
						console.log("localCurrentQuestion", localCurrentQuestion);
						if (localCurrentQuestion.questionType !== undefined) {
							switch (localCurrentQuestion.questionType) {
								case 0:
								case 1:
									setButtonStatus(Array(5).fill("unanswered"));
									setQuestionResult("unanswered");
									break;
								case 2:
									const newMatchTermState = matchTermState;
									newMatchTermState.leftTerms = shuffleFisherYates(localCurrentQuestion.terms[0].items);
									newMatchTermState.rightTerms = shuffleFisherYates(localCurrentQuestion.terms[1].items);
									setMatchTermState(newMatchTermState);
									setButtonStatus(Array(5).fill("grey"));
									break;
								case 3:
									const newBucketState = bucketState;
									const shuffledItemIndexes = shuffleFisherYates(Array.from(Array(10).keys()));
									for (let i = 0; i < 5; i++) {
										if (localCurrentQuestion.items[shuffledItemIndexes[i]] !== undefined) {
											newBucketState.buckets.left.push(localCurrentQuestion.items[shuffledItemIndexes[i]]);
										}
										if (localCurrentQuestion.items[shuffledItemIndexes[i + 5]] !== undefined) {
											newBucketState.buckets.right.push(localCurrentQuestion.items[shuffledItemIndexes[i + 5]]);
										}
									}
									setBucketState(newBucketState);
									setButtonStatus(Array(5).fill("grey"));
									break;
								case 4:
									if (localCurrentQuestion.selectables === undefined) {
										navigation.replace("dashboard");
									}
									loadNextQuestion(localCurrentQuestion);
									break;
								case 5:
									setIsLoading(true);
									setBlankedStatement(localCurrentQuestion.blankedStatement);
									setBlankedWords(shuffleFisherYates( localCurrentQuestion.blankedWords));
									setOriginalStatement(localCurrentQuestion.statement);
									setQuestionTime(60);
									setIsLoading(false);
									break;
								case 6:
									setIsLoading(true);
									console.log("GRIDDING : : :");
									setCurrentQuestion(localCurrentQuestion);
									await locallyCreate(localCurrentQuestion.data)
										.then((grid) => {
											console.log("grid", grid);
											setCrossWordData(grid);
											console.log("crossWordData", crossWordData);
											setQuestionTime(120);
										})
										.finally(() => {
											setIsLoading(false);
										});
									break;
								default:
									console.log("questionType undefined");
									break;
							}
						} else {
							Alert.alert("Question type is undefined.");
							navigation.replace("dashboard");
						}
						gameState.live = 0;
						setIsLoading(false);
						setShouldStartTimer(true);
						gameState.shouldStartTimer = true;
						setRefreshTimer(!refreshTimer);
					}
				} else {
					Alert.alert("Category Document not found.");
					navigation.replace("dashboard");
				}
			})
			.catch(function (error) {
				console.log("Error getting category doc", error);
			});
		setQuestionResult("unanswered");
		setButtonStatus(Array(10).fill("grey"));
		setIsInitialised(true);
	}
	function setAnswerResult(answer) {
		function setMultiChoiceResult(answer) {
			const newButtonStatus = Array(4).fill("multi-unselected");
			newButtonStatus[currentQuestion.correctAnswerIndex] = "true";
			const newQuestionsAnsweredCorrect = questionsAnsweredCorrect;
			const isCorrect = answer === currentQuestion.correctAnswerIndex ? 1 : 0;
			if (answer === currentQuestion.correctAnswerIndex) {
				setScore(score + 1);
				gameState.live++;
				playSound(answerCorrectSound);
			} else {
				newButtonStatus[answer] = "false";
				playSound(incorrectAnswerSound);
			}
			setQuestionResult(isCorrect === 1 ? "correct" : "incorrect");
			newQuestionsAnsweredCorrect[questionKeys[currentQuestionIndex]] = isCorrect;
			setQuestionsAnsweredCorrect(newQuestionsAnsweredCorrect);
			const whereToFindAnswer = {
				learningTopicId: currentQuestion.learningTopicId !== undefined ? currentQuestion.learningTopicId : undefined,
				learningSubTopicId: currentQuestion.learningSubTopicId !== undefined ? currentQuestion.learningSubTopicId : undefined,
				whereToFind: currentQuestion.whereToFind !== undefined ? currentQuestion.whereToFind : "",
			};
			let answerText = "";
			if (answer > 3 || answer < -1) {
				answerText = "unanswered";
			} else {
				answerText = currentQuestion["answers"][answer];
			}
			const newAfterGameInfo = gameState.state.afterGameInfo;
			newAfterGameInfo.push({
				categoryId: categoryId,
				questionText: currentQuestion.questionText,
				answerText: answerText,
				isCorrect: isCorrect,
				whereToFindAnswer: whereToFindAnswer,
			});
			gameState.state.afterGameInfo = newAfterGameInfo;
			setButtonStatus(newButtonStatus);
			const newUserAnswer = {
				questionText: currentQuestion.questionText,
				questionTypeId: currentQuestion.questionType,
				questionType: currentQuestion.questionType == 0 ? "Multiple Choice" : "Fill the Blank",
				correctAnswer: currentQuestion.answers[currentQuestion.correctAnswerIndex],
				userAnswer: currentQuestion.answers[answer],
				score: answer === currentQuestion.correctAnswerIndex ? 1 : 0
			};

			const newUserAnswers = [...userAnswers, newUserAnswer];
			setUserAnswers(newUserAnswers); // add questionType?
		}
		function setMatchTheTermResult(answer) {
			const newButtonStatus = Array(10).fill("multi-unselected");
			const newQuestionsAnsweredCorrect = questionsAnsweredCorrect;
			let isCorrect = 0; // make correct if >3 correct? or show # correct
			let questionScore = 0;
			let answerText = "\n";
			for (let i = 0; i < matchTermState.leftTerms.length; i++) {
				if (
					matchTermState.leftTerms[i] !== undefined &&
					matchTermState.leftTerms[i].index !== undefined &&
					matchTermState.rightTerms[matchTermState.matchedTerms[i]] !== undefined &&
					matchTermState.leftTerms[i].index === matchTermState.rightTerms[matchTermState.matchedTerms[i]].index
				) {
					questionScore++;
				}
				if (matchTermState.leftTerms[i] !== undefined && matchTermState.rightTerms[matchTermState.matchedTerms[i]] !== undefined) {
					answerText += matchTermState.leftTerms[i].name + " - " + matchTermState.rightTerms[matchTermState.matchedTerms[i]].name + "\n"; // find way to get text // display mapping?//same order as display mapping ? // order items?
				}
			}
			let scoreFraction = questionScore / matchTermState.leftTerms.length;
			setCurrentQuestionScore(questionScore);
			setMaxCurrentQuestionScore(currentQuestion.terms[0].items.length);
			setScore(score + scoreFraction); // add totalpoints too
			gameState.live += scoreFraction;
			if (scoreFraction * 100 >= Number(gameInfo.passRate)) {
				setQuestionResult("correct");
				playSound(answerCorrectSound);
				isCorrect = 1;
			} else {
				setQuestionResult("incorrect");
				playSound(incorrectAnswerSound);
				isCorrect = 0;
			}
			newQuestionsAnsweredCorrect[questionKeys[currentQuestionIndex]] = isCorrect;
			setQuestionsAnsweredCorrect(newQuestionsAnsweredCorrect);
			const whereToFindAnswer = {
				learningTopicId: currentQuestion.learningTopicId !== undefined ? currentQuestion.learningTopicId : undefined,
				learningSubTopicId: currentQuestion.learningSubTopicId !== undefined ? currentQuestion.learningSubTopicId : undefined,
				whereToFind: currentQuestion.whereToFind !== undefined ? currentQuestion.whereToFind : "",
			};
			const newAfterGameInfo = gameState.state.afterGameInfo;
			newAfterGameInfo.push({
				categoryId: categoryId,
				questionText: currentQuestion.questionText,
				answerText: answerText,
				matchedTerms: matchTermState.matchedTerms,
				questionType: currentQuestion.questionType,
				questionScore: questionScore,
				isCorrect: isCorrect,
				whereToFindAnswer: whereToFindAnswer,
			});
			gameState.state.afterGameInfo = newAfterGameInfo;
			const newUserAnswer = {
				questionText: currentQuestion.questionText,
				questionTypeId: currentQuestion.questionType,
				questionType: "Match the Terms",
				correctAnswer: currentQuestion.terms,
				userAnswer: matchTermState.matchedTerms,
				score: scoreFraction
			};

			const newUserAnswers = [...userAnswers, newUserAnswer];
			setUserAnswers(newUserAnswers);
		}
		function setWordBucketResult() {
			const newQuestionsAnsweredCorrect = questionsAnsweredCorrect;
			let isCorrect = 0;
			let questionScore = 0;
			let answerText = "\n";
			answerText += currentQuestion.buckets[0].name + ":\n";
			for (let i = 0; i < bucketState.buckets.left.length; i++) {
				answerText += "\t" + bucketState.buckets.left[i].name + "\n";
				if (bucketState.buckets.left[i].matchIndex === 0) {
					questionScore++;
				}
			}
			answerText += "\n" + currentQuestion.buckets[1].name + ":\n";
			for (let i = 0; i < bucketState.buckets.right.length; i++) {
				answerText += "\t" + bucketState.buckets.right[i].name + "\n";
				if (bucketState.buckets.right[i].matchIndex === 1) {
					questionScore++;
				}
			}
			let scoreFraction = questionScore / currentQuestion.items.length;
			setCurrentQuestionScore(questionScore);
			setMaxCurrentQuestionScore(currentQuestion.items.length);
			setScore(score + scoreFraction);
			gameState.live = gameState.live + scoreFraction;
			if (scoreFraction * 100 >= Number(gameInfo.passRate)) {
				setQuestionResult("correct");
				playSound(answerCorrectSound);
				isCorrect = 1;
			} else {
				setQuestionResult("incorrect");
				playSound(incorrectAnswerSound);
				isCorrect = 0;
			}
			newQuestionsAnsweredCorrect[questionKeys[currentQuestionIndex]] = isCorrect;
			setQuestionsAnsweredCorrect(newQuestionsAnsweredCorrect);
			const whereToFindAnswer = {
				learningTopicId: currentQuestion.learningTopicId !== undefined ? currentQuestion.learningTopicId : undefined,
				learningSubTopicId: currentQuestion.learningSubTopicId !== undefined ? currentQuestion.learningSubTopicId : undefined,
				whereToFind: currentQuestion.whereToFind !== undefined ? currentQuestion.whereToFind : "",
			};
			const newAfterGameInfo = gameState.state.afterGameInfo;
			newAfterGameInfo.push({
				categoryId: categoryId,
				questionText: currentQuestion.questionText,
				answerText: answerText,
				questionType: currentQuestion.questionType,
				questionScore: questionScore,
				isCorrect: isCorrect,
				whereToFindAnswer: whereToFindAnswer,
			});
			gameState.state.afterGameInfo = newAfterGameInfo;
			const newUserAnswer = {
				questionText: currentQuestion.questionText,
				questionTypeId: currentQuestion.questionType,
				questionType: "Buckets",
				correctAnswer: {
					buckets: currentQuestion.buckets,
					items: currentQuestion.items
				},
				userAnswer: bucketState,
				score: scoreFraction
			};

			const newUserAnswers = [...userAnswers, newUserAnswer];
			setUserAnswers(newUserAnswers);
		}
		function setImageAreaSelectAnswer(answer) {
			const newQuestionsAnsweredCorrect = questionsAnsweredCorrect;
			let isCorrect = 0;
			let questionScore = 0;
			let answerText = "\n";
			for (let i = 0; i < answer.length; i++) {
				if (answer[i] === true) {
					questionScore++;
				}
			}
			answerText += "Correct areas tapped: " + questionScore;
			if (currentQuestion.selectables.length > 0) {
				answerText += " / " + currentQuestion.selectables.length;
			}
			let scoreFraction = questionScore / currentQuestion.selectables.length;
			setCurrentQuestionScore(questionScore);
			setMaxCurrentQuestionScore(currentQuestion.selectables.length);
			setScore(score + scoreFraction);
			gameState.live = gameState.live + scoreFraction;
			if (scoreFraction * 100 >= Number(gameInfo.passRate)) {
				setQuestionResult("correct");
				playSound(answerCorrectSound);
				isCorrect = 1;
			} else {
				setQuestionResult("incorrect");
				playSound(incorrectAnswerSound);
				isCorrect = 0;
			}
			newQuestionsAnsweredCorrect[questionKeys[currentQuestionIndex]] = isCorrect;
			setQuestionsAnsweredCorrect(newQuestionsAnsweredCorrect);
			const whereToFindAnswer = {
				learningTopicId: currentQuestion.learningTopicId !== undefined ? currentQuestion.learningTopicId : undefined,
				learningSubTopicId: currentQuestion.learningSubTopicId !== undefined ? currentQuestion.learningSubTopicId : undefined,
				whereToFind: currentQuestion.whereToFind !== undefined ? currentQuestion.whereToFind : "",
			};
			const newAfterGameInfo = gameState.state.afterGameInfo;
			newAfterGameInfo.push({
				categoryId: categoryId,
				questionText: currentQuestion.questionText,
				answerText: answerText,
				questionType: currentQuestion.questionType,
				questionScore: questionScore,
				isCorrect: isCorrect,
				whereToFindAnswer: whereToFindAnswer,
			});
			gameState.state.afterGameInfo = newAfterGameInfo;
			const newUserAnswer = {
				questionText: currentQuestion.questionText,
				questionTypeId: currentQuestion.questionType,
				questionType: "Image Match",
				correctAnswer: currentQuestion.selectables,
				userAnswer: answer,
				score: scoreFraction
			};

			const newUserAnswers = [...userAnswers, newUserAnswer];
			setUserAnswers(newUserAnswers);
		}
		if (currentQuestion === undefined || currentQuestion.questionType === undefined) {
			Alert.alert("The current question or question type is undefined");
			navigation.replace("dashboard"); // test
		}
		const questionType = currentQuestion.questionType;
		switch (questionType) {
			case 0:
			case 1:
				setMultiChoiceResult(answer);
				break;
			case 2:
				setMatchTheTermResult(answer);
				break;
			case 3:
				setWordBucketResult();
				break;
			case 4:
				setImageAreaSelectAnswer(answer);
				break;
			case 5:
				submitBlanked();
				break;
			default: // test
				Alert.alert("The question type is not valid");
				navigation.replace("dashboard");
				break;
		}
	}
	function selectMatchedTerm(answer) {
		const newMatchTermState = matchTermState;
		if (newMatchTermState.selectedLeftTermIndex < 0) {
			if (answer >= 0 && answer < 5) {
				newMatchTermState.selectedLeftTermIndex = answer;
			}
		} else {
			if (answer >= 5 && answer < 10) {
				newMatchTermState.matchedTerms[newMatchTermState.selectedLeftTermIndex] = answer - 5;
				newMatchTermState.selectedLeftTermIndex = -1;
			} else {
				newMatchTermState.matchedTerms[newMatchTermState.selectedLeftTermIndex] = -1;
			}
		}
		let areAllMatched = true;

		for (let i = 0; i < newMatchTermState.leftTerms.length; i++) {
			if (newMatchTermState.matchedTerms[i] < 0) {
				areAllMatched = false;
			}
		}
		if (areAllMatched) {
			submitMatchedTermAnswer();
		}
		setMatchTermState(newMatchTermState);
		setTriggerRefresh(!triggerRefresh);
	}
	function submitMatchedTermAnswer() {
		setAnswerResult(0);
		setTimeout(() => {
			setIsLoadingNextQuestion(true);
		}, 1500);
	}
	function selectBucketItem(answerIndex) {
		const newBucketState = bucketState;
		newBucketState.selectedItemIndex = answerIndex;
		setBucketState(newBucketState);
		setTriggerRefresh(!triggerRefresh);
	}
	function moveBucketItem(bucketIndex) {
		const newBucketState = bucketState;
		const selectedItemBucketIndex = newBucketState.selectedItemIndex < 10 ? 0 : 1;
		const indexInBucket = newBucketState.selectedItemIndex < 10 ? newBucketState.selectedItemIndex : newBucketState.selectedItemIndex - 10;
		if (newBucketState.selectedItemIndex === -1 || selectedItemBucketIndex === bucketIndex) {
			newBucketState.selectedItemIndex = -1;
			setBucketState(newBucketState);
			setTriggerRefresh(!triggerRefresh);
			return;
		}
		if ((bucketIndex === 0 && bucketState.buckets.right.length === 0) || (bucketIndex === 1 && bucketState.buckets.left.length === 0)) {
			return;
		}
		if (selectedItemBucketIndex === 0) {
			newBucketState.buckets.right.push(newBucketState.buckets.left[indexInBucket]); // deep copy?
			newBucketState.buckets.left.splice(indexInBucket, 1);
		} else {
			newBucketState.buckets.left.push(newBucketState.buckets.right[indexInBucket]); // deep copy?
			newBucketState.buckets.right.splice(indexInBucket, 1);
		}
		newBucketState.selectedItemIndex = -1;
		setBucketState(newBucketState);
		setTriggerRefresh(!triggerRefresh);
	}
	function submitBucketAnswer() {
		setAnswerResult(1);
		setIsQuestionActive(false);
		setTimeout(() => {
			setIsLoadingNextQuestion(true);
		}, 1500); // maybe rather in setAnswerResult
	}
	function submitImageSelectAnswer(categoriesClicked) {
		setAnswerResult(categoriesClicked);
		setIsQuestionActive(false);
		setTimeout(() => {
			setIsLoadingNextQuestion(true);
		}, 1500);
	}
	function selectAnswer(answer) {
		setShouldStopTimer(true);
		if (isQuestionActive === true && currentQuestion !== undefined && currentQuestionIndex < questionsToAskCount) {
			switch (currentQuestion.questionType) {
				case 0:
				case 1:
					setIsQuestionActive(false);
					setAnswerResult(answer);
					setTimeout(() => {
						setIsLoadingNextQuestion(true);
					}, 1500);
					break;
				case 2:
					selectMatchedTerm(answer);
					break;
				case 3:
					submitBucketAnswer(1);
					break;
				case 4:
					submitImageSelectAnswer(answer);
					break;
				case 5:
					submitBlanked();
					break;
				case 6:
					submitCrossWord();
				default:
					break;
			}
		}
	}
	async function loadNextQuestion(question) {
		setCurrentQuestionScore(-1);
		setMaxCurrentQuestionScore(-1);
		switch (question.questionType) {
			case 1:
				break;
			case 2:
				const newMatchTermState = {
					leftTerms: [],
					rightTerms: [],
					matchedTerms: {
						0: -1,
						1: -1,
						2: -1,
						3: -1,
						4: -1,
					},
					selectedLeftTermIndex: -1,
					selectedRightTermIndex: -1,
				};
				console.log("TERMS : : :", question);
				newMatchTermState.leftTerms = shuffleFisherYates(question.terms[0].items); // may need to disable shuffling of left column with current setup
				newMatchTermState.rightTerms = shuffleFisherYates(question.terms[1].items);
				setMatchTermState(newMatchTermState);
				break;
			case 3:
				setQuestionResult("grey");
				setButtonStatus(Array(5).fill("grey")); //10?
				const newBucketState = bucketState;
				const shuffledItemIndexes = shuffleFisherYates(Array.from(Array(10).keys())); // support less items?
				newBucketState.buckets.left = [];
				newBucketState.buckets.right = [];
				for (let i = 0; i < 5; i++) {
					if (question.items[shuffledItemIndexes[i]] !== undefined) {
						newBucketState.buckets.left.push(question.items[shuffledItemIndexes[i]]);
					}
					if (question.items[shuffledItemIndexes[i + 5]] !== undefined) {
						newBucketState.buckets.right.push(question.items[shuffledItemIndexes[i + 5]]);
					}
				}
				setBucketState(newBucketState);
				break;
			case 4:
				let gridData = createGrid(question.gridWidth, question.gridHeight);
				gridData = addToGrid(gridData, question);
				setTapsLeft(question.selectables.length);
				setWasGroupSelected(Array(question.selectables.length).fill(false));
				break;
			case 5:
				setIsLoading(true);
				setBlankedStatement(question.blankedStatement);
				setBlankedWords(shuffleFisherYates(question.blankedWords));
				setOriginalStatement(question.statement);
				setIsLoading(false);
				break;
			case 6:
				setIsLoading(true);
				console.log("GRIDDING : : :");
				await locallyCreate(question.data)
					.then((grid) => {
						console.log("grid", grid);
						setCrossWordData(grid);
						console.log("crossWordData", crossWordData);
						setQuestionTime(120);
					})
					.finally(() => {
						setIsLoading(false);
					});
				break;

			default:
				break;
		}
	}
	function next() {
		const newQuestionIndex = currentQuestionIndex + 1;
		setCurrentQuestionIndex(newQuestionIndex);
		if (newQuestionIndex < questionsToAskCount && questionKeys !== undefined && newQuestionIndex < questionKeys.length) {
			setCurrentQuestion(gameInfo.questions[questionKeys[newQuestionIndex]]);
			loadNextQuestion(gameInfo.questions[questionKeys[newQuestionIndex]]);
			setButtonStatus(Array(4).fill("unanswered"));
			setQuestionResult("unanswered");
			setImageBackgroundIndex(Math.floor(Math.random() * (backgroundImages.length - 1)));
			setIsQuestionActive(true);
			setShouldStartTimer(true);
			gameState.shouldStartTimer = true;
			setRefreshTimer(!refreshTimer);
		} else {
			setShouldStopTimer(true);
			let correctCount = 0;
			console.log("QUESTIONS : : :", questionsAnsweredCorrect);
			for (let key in questionsAnsweredCorrect) {
				if (questionsAnsweredCorrect[key] === 1) {
					correctCount++;
				}
			}
			console.log("CORRECT COUNT : : :", correctCount);
			console.log("QUESTIONS TO ASK COUNT : : :", questionsToAskCount);
			console.log("INFORMATION IN : : :", categoryId, gameState.live);
			setCategoryResults(categoryId, correctCount);
			nextCategory(correctCount);
			setIsQuestionActive(false);
			setIsQuestionActive(false);
		}
	}
	function setCategoryResults(currentCategoryID, currentCategoryScore) {
		console.log("SET CATEGORY RESULTS : : :", currentCategoryID, currentCategoryScore);
		gameState.state.categoryScores[currentCategoryID] = Math.round(currentCategoryScore * 100) / 100;
		gameState.state.uncompletedCategories = gameState.state.uncompletedCategories.filter(function (e) {
			return e !== currentCategoryID;
		});
		gameState.live = 0;
	}
	function nextCategory(correctCount) {
		const remainingCategoriesCount = gameState.state.uncompletedCategories.length;
		if (remainingCategoriesCount > 0) {
			if (gameState.categoryInfo && gameState.gameInfo.categoryRetries >= 0) {
				uploadAnswers(() => {
					closePage().then(() => {
						navigation.replace("game_categories");
					});
				});
			} else {
				navigation.replace("game");
			}
		} else {
			if (userAnswers !== undefined) {
				uploadAnswers();
				getAndSetGameInfo(correctCount);
			} else {
				console.log("UsersAnswers undefined!!!");
			}
		}
	}
	function getAndSetGameInfo(correctCount) {
		const path = ["Companies", companyName, "Users", userId];
		getElement(path)
			.get()
			.then(function (doc) {
				if (doc.exists) {
					const profileData = doc.data();
					const path = ["Companies", companyName, "Departments", departmentId];
					getElement(path)
						.get()
						.then(function (doc2) {
							if (doc2.exists) {
								const path = ["Companies", companyName, "Departments", departmentId, "Leaderboard", "Leaderboard"];
								getElement(path)
									.get()
									.then(function (doc3) {
										updateGameInfo(profileData, gameState.gameInfo.maximumGameScore, correctCount);
									})
									.catch(function (error) {
										navigation.replace("after_game");
									});
							} else {
								navigation.replace("after_game");
							}
						})
						.catch(function (error) {
							navigation.replace("after_game");
						});
				} else {
					navigation.replace("after_game");
				}
			})
			.catch(function (error) {
				navigation.replace("after_game");
			});
	}
	function updateGameInfo(profileData, maximumScore, correctCount) {
		try {
			const isErrorEncounterd = false;
			const newProfileData = profileData;

			if (newProfileData.gamesCompleted !== undefined) {
				newProfileData.gamesCompleted[departmentId][gameId][categoryId] = correctCount;
			} else {
				newProfileData.gamesCompleted = {};
				if (newProfileData.gamesCompleted[departmentId] === undefined) {
					newProfileData.gamesCompleted[departmentId] = {};
				}
				if (newProfileData.gamesCompleted[departmentId][gameId] === undefined) {
					newProfileData.gamesCompleted[departmentId][gameId] = {};
				}
				newProfileData.gamesCompleted[departmentId][gameId][categoryId] = correctCount;
				globalState.userProfile = newProfileData;
			}

			const gameScore = calculateGameAnswersCorrect(gameState.state.categoryScores);
			const gameScorePercentage = Math.round((gameScore / maximumScore) * 100);

			updateLeaderboard(newProfileData, gameScore, gameScorePercentage, maximumScore);
			updateProfile(gameScore, maximumScore);

			const uploadToLeaderboard = Firebase.functions().httpsCallable("uploadToLeaderboard");
			gameState.isLeaderboardUpdated = false;
			uploadToLeaderboard({
				dbContext: globalState.DBPath,
				profile: newProfileData,
				score: !isNaN(gameScore) && gameScore > 0 ? gameScore : 0,
				isGameLeaderboard: true,
				maximumGameScore: maximumScore,
			})
				.then(function (response) {
					gameState.isLeaderboardUpdated = true;
				})
				.catch((error) => {
					gameState.isLeaderboardUpdated = true;
				});

			const updateAchievements = Firebase.functions().httpsCallable("updateAchievements");
			updateAchievements({ dbContext: globalState.DBPath })
				.then(function (response) {})
				.catch((error) => {
					gameState.isLeaderboardUpdated = true;
				});

			if (isErrorEncounterd) {
				try {
					let displayedMaximumScore;
					displayedMaximumScore = gameState.maximumGameScore;
					const logErrors = Firebase.functions().httpsCallable("logErrors");
					logErrors({ question: currentQuestion, globalState: globalState, gameState: gameState, maximumGameScore: displayedMaximumScore, error: "invalid maximumScore in updateGameInfo" }) // log password and email just in testing
						.then(function (response) {})
						.catch((error) => {
							console.log("logErrors error, ", error);
						});
				} catch (e) {
					console.log("log errors failed");
					console.log(e);
				}
			}
		} catch {}

		// Reset the game category scores
		gameState.state.categoryScores = {};
		navigation.replace("after_game");
	}

	function uploadAnswers(callback) {
		const currentDate = new Date();
		const answerDocName =
			currentDate.getFullYear() + "_" + currentDate.getMonth() + "_" + currentDate.getDate() + "_" + currentDate.getHours() + "_" + currentDate.getMinutes() + "_" + currentDate.getSeconds();
		const path = ["Companies", globalState.DBPath.companyId, "Users", globalState.DBPath.userId, "Answers", answerDocName];
		getElement(path)
			.set({
				answerID: 1,
				departmentId: departmentId,
				gameId: gameId,
				game: gameState?.gameInfo?.name || "Not found",
				categoryId: categoryId,
				category: gameState?.categoryInfo?.name || "Not found",
				date: currentDate,
				answers: userAnswers,
				answersCorrect: questionsAnsweredCorrect,
				score: userAnswers.reduce((sum, ans) => { return sum + ans.score }, 0) / userAnswers.length,
			})
			.then(() => {
				try {
					callback();
				} catch {}
			})
			.catch((e) => {
				console.log("uploadAnswerIssue", e);
			});
	}
	function updateLeaderboard(profileData, gameScore, gameScorePercentage, maximumGameScore) {
		let newEntry;
		let localLeaderboard = gameState.localGameLeaderboard;
		if (profileData.userInfo.imageUrl !== undefined && profileData.userInfo.imageUrl.length > 0) {
			newEntry = {
				userId: userId,
				name: profileData.userInfo.firstName + " " + profileData.userInfo.lastName,
				imageUrl: profileData.userInfo.imageUrl, //NB update here
				score: gameScorePercentage,
				maximumCorrect: maximumGameScore,
				points: gameScore,
			};
		} else {
			newEntry = {
				userId: userId,
				name: profileData.userInfo.firstName + " " + profileData.userInfo.lastName,
				score: gameScorePercentage,
				maximumCorrect: maximumGameScore,
				points: gameScore,
			};
		}
		if (localLeaderboard === undefined || localLeaderboard.length < 1) {
			localLeaderboard = [newEntry];
			gameState.localGameLeaderboard = localLeaderboard;
		} else {
			let userPositionInLeaderboard = 0;
			let isUserInLeaderboard = false;
			for (let position = 0; position < localLeaderboard.length; position++) {
				if (localLeaderboard[position].userId === userId) {
					isUserInLeaderboard = true;
					userPositionInLeaderboard = position;
					break;
				}
			}
			if (isUserInLeaderboard) {
				localLeaderboard.splice(userPositionInLeaderboard, 1);
			}
			let positionToInsert = 0;
			for (let position = 0; position < localLeaderboard.length; position++) {
				if (gameScorePercentage < localLeaderboard[position].score) {
					positionToInsert = position + 1;
				}
			}
			localLeaderboard.splice(positionToInsert, 0, newEntry);
			gameState.localGameLeaderboard = localLeaderboard;
		}
	}
	function updateProfile(gameScore, maximumGameScore) {
		if (gameState !== undefined && gameState.state.categoryScores !== undefined && Object.keys(gameState.state.categoryScores).length > 0) {
			const additionalStats = {
				answersCorrectCount: gameScore,
				possibleAnswersCorrectCount: maximumGameScore,
				playTime: gameState.state.startTime !== undefined ? Math.round((new Date() - gameState.state.startTime) / 1000) : 0, //convert to second
				recentGameDate: new Date(),
			};
			const updateUserGameStats = Firebase.functions().httpsCallable("updateUserGameStats");
			updateUserGameStats({ dbContext: globalState.DBPath, additionalStats: additionalStats, categoryScores: gameState.state.categoryScores, maximumGameScore: maximumGameScore })
				.then(function (response) {})
				.catch((e) => {
					// run local update if failed?
					console.log("Error for updateUserGameStats: ", e);
				});
			const updateGameStats = Firebase.functions().httpsCallable("updateGameStats");
			updateGameStats({ dbContext: globalState.DBPath, additionalStats: additionalStats, maximumGameScore: maximumGameScore })
				.then(function (response) {})
				.catch((e) => {
					console.log("Error for updateGameStats: ", e);
				});
		} else {
			console.log("failed to update profile: gameState not set");
		}
	}
	function calculateGameAnswersCorrect(categoryScores) {
		let totalAnswersCorrect = 0;
		for (const currentCategoryID in categoryScores) {
			totalAnswersCorrect += categoryScores[currentCategoryID];
		}
		return Math.round(totalAnswersCorrect * 100) / 100;
	}
	function setModalDisplay(shouldDisplay) {
		setShowModal(shouldDisplay);
	}
	function timeUp() {
		if (currentQuestion.questionType === 2) {
			submitMatchedTermAnswer(0);
		}
		selectAnswer(-1);
	}
	function setTimeTaken(timeTaken) {
		setPlayTime(playTime + timeTaken);
	}
	function timerIsStarted() {
		if (shouldStartTimer === true) {
			setShouldStartTimer(false);
			// gameState.shouldStartTimer = true
		}
	}
	function timerIsStopped() {
		if (shouldStopTimer === true) {
			setShouldStopTimer(false);
		}
	}
	function shuffleFisherYates(array) {
		let i = array.length;
		while (i--) {
			const ri = Math.floor(Math.random() * (i + 1));
			[array[i], array[ri]] = [array[ri], array[i]];
		}
		return array;
	}
	function parseQuestionText(questionText) {
		const fillInSpaceLength = 7;
		if (questionText !== undefined) {
			let newText = questionText;
			for (let i = questionText.length - 1; i >= 0; i--) {
				if (questionText[i] === "_") {
					// To support wrods that use underscore && ((i - 1 > 0 && questionText[i-1] == " ") || i == 0)) {
					newText = newText.slice(0, i) + "_".repeat(fillInSpaceLength) + newText.slice(i);
				}
			}
			return newText;
		} else {
			return LOADING_QUESTION;
		}
	}
	function renderLearningMedia(mediaUrl, mediaType) {
		const validMediaTypes = ["video"];

		if (validMediaTypes.indexOf(mediaType) < 0) {
			return null;
		}
		if (mediaType === "video") {
			return (
				<View style={styles.videoContainer}>
					<WebView
						style={styles.WebView}
						javaScriptEnabled={true}
						domStorageEnabled={true}
						// source={{uri: 'https://www.youtube.com/embed/YE7VzlLtp-4' }}
						source={{ uri: mediaUrl }}
					/>
				</View>
			);
		}
	}
	function renderLeaderboardModal() {
		if (globalState.isWebDisplay === true) {
			return null;
		} else {
			return <LeaderboardModal setModalDisplay={setModalDisplay} modalVisible={showModal} />;
		}
	}
	function renderMultiChoiceButtons() {
		const customRowStyle = StyleSheet.create({
			answerButtonRowTop: {
				minHeight: hp("18%"),
				maxHeight: hp("18%"),
				// backgroundColor: '#000'
			},
			answerButtonRowBottom: {
				minHeight: hp("18%"),
				maxHeight: hp("18%"),
				// backgroundColor: '#000'
			},
		});
		return (
			<View style={styles.answerButtonsContainer}>
				<View style={[styles.answerButtonRowTop, customRowStyle.answerButtonRowTop]}>
					<GameButton
						answerIndex={0}
						answerOptionText={currentQuestion !== undefined && currentQuestion.answers !== undefined ? currentQuestion.answers["0"] : "A?"}
						selectAnswer={selectAnswer}
						buttonStatus={buttonStatus[0]}
						disabled={!isQuestionActive}></GameButton>
					<GameButton
						answerIndex={1}
						answerOptionText={currentQuestion !== undefined && currentQuestion.answers !== undefined ? currentQuestion.answers["1"] : "B?"}
						selectAnswer={selectAnswer}
						buttonStatus={buttonStatus[1]}
						customStyle={button2Style.button}
						disabled={!isQuestionActive}></GameButton>
				</View>
				<View style={[styles.answerButtonRowBottom, customRowStyle.answerButtonRowBottom]}>
					<GameButton
						answerIndex={2}
						answerOptionText={currentQuestion !== undefined && currentQuestion.answers !== undefined ? currentQuestion.answers["2"] : "C?"}
						selectAnswer={selectAnswer}
						buttonStatus={buttonStatus[2]}
						customStyle={button3Style.button}
						disabled={!isQuestionActive}></GameButton>
					<GameButton
						answerIndex={3}
						answerOptionText={currentQuestion !== undefined && currentQuestion.answers !== undefined ? currentQuestion.answers["3"] : "D?"}
						selectAnswer={selectAnswer}
						buttonStatus={buttonStatus[3]}
						customStyle={button4Style.button}
						disabled={!isQuestionActive}></GameButton>
				</View>
			</View>
		);
	}
	function renderMatchTermButtons() {
		let i;
		const defaultForegroundColours = ["#FF5454", "#549EFF", "#FF5488", "#FF924A", "#FF5454"];
		const defaultBackgroundColours = ["#EB3C3C", "#5378FF", "#DB3D6C", "#FF6500", "#EB3C3C"];
		const selectedButtonColours = [];
		for (i = 0; i < defaultForegroundColours.length; i++) {
			selectedButtonColours.push({
				foreground: defaultForegroundColours[i],
				background: defaultBackgroundColours[i],
			});
		}
		const buttonColours = [];
		for (i = 0; i < 10; i++) {
			buttonColours.push({
				foreground: "#F0DEDE",
				background: "#DEBEBE",
			});
		}
		Object.keys(matchTermState.matchedTerms).forEach((leftIndex) => {
			if (matchTermState.matchedTerms[leftIndex] > -1) {
				buttonColours[leftIndex] = selectedButtonColours[leftIndex];
				buttonColours[matchTermState.matchedTerms[leftIndex] + 5] = selectedButtonColours[leftIndex];
			}
		});
		if (matchTermState.selectedLeftTermIndex >= 0) {
			buttonColours[matchTermState.selectedLeftTermIndex] = selectedButtonColours[matchTermState.selectedLeftTermIndex];
		}
		function mapMatchTermRow() {
			if (matchTermState === undefined || matchTermState.leftTerms === undefined || matchTermState.rightTerms === undefined) {
				return;
			}
			return matchTermState.leftTerms.map((term, i) => (
				<View style={styles.answerButtonRowTop} key={i + "v"}>
					<GameButton
						answerIndex={i}
						answerOptionText={matchTermState !== undefined && matchTermState.leftTerms !== undefined ? matchTermState.leftTerms[i].name : "A?"}
						key={i + "a"}
						selectAnswer={selectAnswer}
						customForegroundColor={buttonColours[i].foreground}
						customBackgroundColor={buttonColours[i].background}
						buttonStatus={buttonStatus[0]}
						disabled={!isQuestionActive}></GameButton>
					<GameButton
						answerIndex={i + 5}
						answerOptionText={matchTermState !== undefined && matchTermState.rightTerms !== undefined ? matchTermState.rightTerms[i].name : "B?"}
						key={i + "b"}
						selectAnswer={selectAnswer}
						customForegroundColor={buttonColours[i + 5].foreground}
						customBackgroundColor={buttonColours[i + 5].background}
						buttonStatus={buttonStatus[1]}
						customStyle={button2Style.button}
						disabled={!isQuestionActive}></GameButton>
				</View>
			));
		}
		return <View style={styles.answerButtonsContainer}>{mapMatchTermRow()}</View>;
	}
	function renderWordBucketAnswerInterface() {
		let i;
		const defaultForegroundColours = ["#FF5454", "#549EFF", "#FF5488", "#FF924A", "#FF5454"];
		const defaultBackgroundColours = ["#EB3C3C", "#5378FF", "#DB3D6C", "#FF6500", "#EB3C3C"];
		let selectedButtonColours = [];
		for (i = 0; i < defaultForegroundColours.length; i++) {
			// not size 10 = issue
			selectedButtonColours.push({
				foreground: defaultForegroundColours[i],
				background: defaultBackgroundColours[i],
			});
		}
		const buttonColours = [];
		for (i = 0; i < 20; i++) {
			buttonColours.push({
				foreground: "#549EFF",
				background: "#5378FF",
			});
		}
		if (bucketState.selectedItemIndex >= 0) {
			buttonColours[bucketState.selectedItemIndex].foreground = "#FF924A";
			buttonColours[bucketState.selectedItemIndex].background = "#FF6500";
		}
		function mapBucketRow() {
			if (bucketState === undefined || bucketState.buckets === undefined || bucketState.buckets.left === undefined || bucketState.buckets.right === undefined) {
				return;
			}
			const numRows = bucketState.buckets.left.length >= bucketState.buckets.right.length ? bucketState.buckets.left.length : bucketState.buckets.right.length;
			const rows = Array(numRows).fill(0);
			return rows.map((term, i) => (
				<View style={styles.answerButtonRowTop} key={i + "v"}>
					<GameButton
						answerIndex={i}
						isVisible={i < bucketState.buckets.left.length}
						answerOptionText={bucketState.buckets.left[i] !== undefined ? bucketState.buckets.left[i].name : "A?"}
						selectAnswer={selectBucketItem}
						customForegroundColor={buttonColours[i].foreground}
						customBackgroundColor={buttonColours[i].background}
						buttonStatus={buttonStatus[0]}
						disabled={!isQuestionActive}>
						key={i + "a"}
					</GameButton>
					<GameButton
						answerIndex={i + 10}
						isVisible={i < bucketState.buckets.right.length}
						// answerOptionText={bucketState != undefined && bucketState.buckets != undefined && bucketState.buckets.right != undefined && bucketState.buckets.right[i] != undefined ? bucketState.buckets.right[i].name : "B?"}
						answerOptionText={bucketState.buckets.right[i] !== undefined ? bucketState.buckets.right[i].name : "B?"}
						selectAnswer={selectBucketItem}
						customForegroundColor={buttonColours[Number(i + 5)].foreground}
						customBackgroundColor={buttonColours[i + 5].background}
						buttonStatus={buttonStatus[1]}
						customStyle={button2Style.button}
						disabled={!isQuestionActive}>
						key={i + "b"}
					</GameButton>
				</View>
			));
		}
		return (
			<View style={styles.answerButtonsContainer}>
				<View style={styles.answerButtonRowTop}>
					<View style={{ minWidth: wp(49), maxWidth: wp(49), justifyContent: "center", alignItems: "center" }}>
						<Text
							style={{
								maxWidth: wp(49),
								textAlign: "center",
								fontSize: hp("3%"),
								backgroundColor: "#FF924A",
								borderRadius: hp("2%"),
								color: "#FFF",
								opacity: 0.87,
								paddingHorizontal: wp(1),
							}}>
							{" "}
							{currentQuestion.buckets[0].name}
						</Text>
					</View>
					<View style={{ minWidth: wp(49), maxWidth: wp(49), justifyContent: "center", alignItems: "center" }}>
						<Text
							style={{
								maxWidth: wp(49),
								textAlign: "center",
								fontSize: hp("3%"),
								backgroundColor: "#FF5488",
								borderRadius: hp("3%"),
								color: "#FFF",
								opacity: 0.87,
								paddingHorizontal: wp(1),
							}}>
							{" "}
							{currentQuestion.buckets[1].name}
						</Text>
					</View>
				</View>
				{mapBucketRow()}
				<View style={{ maxHeight: hp(10), minHeight: hp(10) }}>
					<StyledButton text={"Done"} onPressFunction={submitBucketAnswer} backgroundColor={"#1FC75A"} shadowColour={"#009936"}></StyledButton>
				</View>
			</View>
		);
	}
	function bucketSelectAreas() {
		if (currentQuestion === undefined || currentQuestion.questionType !== 3) {
			return null;
		}
		if (bucketState.selectedItemIndex === -1) {
			return null;
		} else {
			return (
				<View
					style={{
						position: "absolute",
						bottom: 0,
						left: 0,
						minHeight: 0.5 * windowHeight,
						maxHeight: 0.5 * windowHeight,
						width: windowWidth,
						height: 0.5 * windowHeight,
						minWidth: windowWidth,
						maxWidth: windowWidth,
						opacity: 0.1,
						flexDirection: "row",
					}}>
					<TouchableOpacity
						style={{
							minWidth: wp(49),
							maxWidth: wp(49),
							backgroundColor: "#FF924A",
							minHeight: 0.5 * windowHeight,
						}}
						activeOpacity={0.5}
						onPress={() => {
							moveBucketItem(0);
						}}
						disabled={props.disabled}></TouchableOpacity>
					<TouchableOpacity
						style={{
							minWidth: wp(49),
							maxWidth: wp(49),
							backgroundColor: "#FF5488",
							minHeight: 0.5 * windowHeight,
						}}
						activeOpacity={0.5}
						onPress={() => {
							moveBucketItem(1);
						}}
						disabled={props.disabled}></TouchableOpacity>
				</View>
			);
		}
	}
	function renderImageAreaSelect(questionData, gridData) {
		const numRows = questionData.gridHeight;
		const numColumns = questionData.gridWidth;
		const scaling = windowWidth / questionData.image.width;
		const effectiveImageHeight = questionData.image.height * scaling;
		const cellHeight = effectiveImageHeight / numRows;
		const cellWidth = windowWidth / numColumns;
		const gridStyles = StyleSheet.create({
			cellContent: {
				margin: 0,
				opacity: 0.0,
				padding: 0,
				borderWidth: 0,
				height: "100%",
			},
			cell: {
				maxHeight: cellHeight,
				minHeight: cellHeight,
				maxWidth: cellWidth,
				minWidth: cellWidth,
			},
			gridImage: {
				minWidth: windowWidth,
				maxWidth: windowWidth,
				minHeight: effectiveImageHeight,
				maxHeight: effectiveImageHeight,
			},
			grid: {
				flexDirection: "column",
			},
			row: {
				flexDirection: "row",
			},
		});
		function renderCell(cell) {
			if (cell === undefined) {
				return undefined;
			}
			return (
				<TouchableOpacity
					style={gridStyles.cell}
					activeOpacity={0.98}
					onPress={() => {
						if (!isQuestionActive) {
							return;
						}
						if (wasGroupSelected === undefined) {
							navigation.replace("dashboard");
							return;
						}
						const newWasGroupSelected = wasGroupSelected;
						let newTapsLeft = tapsLeft;
						if (cell < 0) {
							newTapsLeft = tapsLeft - 1;
							setTapsLeft(newTapsLeft);
						} else {
							if (wasGroupSelected[cell] === false) {
								newWasGroupSelected[cell] = true;
								setWasGroupSelected(newWasGroupSelected);
								newTapsLeft = tapsLeft - 1;
								setTapsLeft(newTapsLeft);
							} else {
								console.log("already selected");
							}
						}
						if (newTapsLeft <= 0) {
							selectAnswer(newWasGroupSelected);
						}
					}}>
					<View style={gridStyles.cellContent}>
						<Text style={{ color: "#FFF", fontSize: 15, opacity: 0.87, textAlign: "center" }}>{cell >= 0 ? cell : ""}</Text>
					</View>
				</TouchableOpacity>
			);
		}
		function renderRow(rowData) {
			if (rowData === undefined) {
				return undefined;
			}
			return <View style={gridStyles.row}>{rowData.map((cell, i) => renderCell(cell, i))}</View>;
		}
		function renderGrid(gridData) {
			if (gridData === undefined) {
				return null;
			}
			return <View style={gridStyles.grid}>{gridData.map((rowData, i) => renderRow(rowData, i))}</View>;
		}
		const backgroundImageUrl = questionData.image.url;
		return (
			<ImageBackground source={{ uri: backgroundImageUrl }} style={gridStyles.gridImage} resizeMode="contain">
				{renderGrid(gridData)}
			</ImageBackground>
		);
	}
	function createGrid(width, height) {
		const grid = [];
		for (let i = 0; i < height; i++) {
			grid.push(Array(width).fill(-1));
		}
		setGridData(grid);
		return grid;
	}
	function addToGrid(gridData, questionData) {
		if (questionData === undefined) {
			console.log("error questionData is undefined");
		}
		const newGridData = gridData;
		const selectableGroups = questionData.selectables; // NB SET SELECTABLES to maintain order? or at least keep i question?
		selectableGroups.forEach((selectableGroup, groupIndex) => {
			const currentGroupIndex = groupIndex;
			const clickableGroup = selectableGroup.clickables;
			clickableGroup.forEach((selectablePosition) => {
				newGridData[selectablePosition.y][selectablePosition.x] = currentGroupIndex;
			});
		});
		return newGridData;
	}
	function renderAnswerButtons(questionType) {
		switch (questionType) {
			case 0:
			case 1:
				return renderMultiChoiceButtons();
			case 2:
				return renderMatchTermButtons();
			case 3:
				return renderWordBucketAnswerInterface();
			case 4:
				return renderImageAreaSelect(currentQuestion, gridData);
			case 5:
				return renderStatementBlanking();
			case 6:
				return null;
			default:
				return null;
		}
	}
	function renderStatementBlanking() {

		if (!blankedStatement || blankedStatement.length <= 0) {
			return null;
		}
		
		return (
			<View style={{ flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
				<View style={{ flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", width: "33%", minWidth: 250 }}>
					<Text style={{ color: "#000000", backgroundColor: "#FFFFFF", fontSize: 20, opacity: 0.87, textAlign: "center" }}>
						{blankedStatement}
					</Text>
				</View>
				<View style={{ flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", width: "33%", minWidth: 250 }}>
					<Text style={{ backgroundColor: "rgba(255,255,255,0.9)", color: "#000000" }}>
						Please select the words you think are missing from the statement above.
					</Text>
					<Text style={{ backgroundColor: "rgba(255,255,255,0.9)", color: "#000000" }}>
						You can also remove words you have selected by tapping them again.
					</Text>
				</View>
				{renderBlankedWords()}
				<View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
					<Button
						title="Submit"
						disabled={!readyForSubmit}
						onPress={() => {
							submitBlanked();
						}}
					/>
				</View>
			</View>
		);

	}
	const buttonContentStyle = StyleSheet.create({
		buttonContent: {
			color: "black",
			fontSize: 20,
			margin: 10,
			backgroundColor: "rgba(248,248,248,0.84)",
			borderColor: "rgba(0,0,0,0.84)",
			padding: 10,
			borderWidth: 0,
			height: "100%",
			borderRadius: 10,
			fontStyle: "bold",
		},
	});
	function submitBlankingAnswer(valid) {
		if (valid) {
			const isCorrect = valid ? 1 : 0;
			setScore(score + 1);
			gameState.live++;
			setQuestionResult("correct");
			const newQuestionsAnsweredCorrect = questionsAnsweredCorrect;
			newQuestionsAnsweredCorrect[questionKeys[currentQuestionIndex]] = isCorrect;
			setQuestionsAnsweredCorrect(newQuestionsAnsweredCorrect);
			setCurrentQuestionScore(1);
			setCategoryResults(categoryId, 1);
			setScore(score + 1);
			setQuestionResult("correct");
			setIsQuestionActive(false);
			setTimeout(() => {
				setIsLoadingNextQuestion(true);
			}, 1500);
		} else {
			setQuestionResult("incorrect");
			const newQuestionsAnsweredCorrect = questionsAnsweredCorrect;
			newQuestionsAnsweredCorrect[questionKeys[currentQuestionIndex]] = 0;
			setQuestionsAnsweredCorrect(newQuestionsAnsweredCorrect);
			setCurrentQuestionScore(0);
			setCategoryResults(categoryId, 0);
			setIsQuestionActive(false);
			setTimeout(() => {
				setIsLoadingNextQuestion(true);
			}, 1500);
		}
	}
	function submitBlanked() {

		const valid = originalStatement.toLowerCase() === blankedStatement.toLowerCase();

		const newUserAnswer = {
			questionText: currentQuestion.questionText,
			questionTypeId: currentQuestion.questionType,
			questionType: "Statement Blanking",
			correctAnswer: originalStatement,
			userAnswer: blankedStatement,
			score: valid ? 1 : 0
		};
		
		const newUserAnswers = [...userAnswers, newUserAnswer];
		setUserAnswers(newUserAnswers);

		const whereToFindAnswer = {
			learningTopicId: currentQuestion.learningTopicId !== undefined ? currentQuestion.learningTopicId : undefined,
			learningSubTopicId: currentQuestion.learningSubTopicId !== undefined ? currentQuestion.learningSubTopicId : undefined,
			whereToFind: currentQuestion.whereToFind !== undefined ? currentQuestion.whereToFind : "",
		};
		const newAfterGameInfo = gameState.state.afterGameInfo;
		newAfterGameInfo.push({
			categoryId: categoryId,
			questionText: currentQuestion.questionText,
			answerText: blankedStatement,
			isCorrect: valid ? 1 : 0,
			whereToFindAnswer: whereToFindAnswer,
		});
		console.log("newAfterGameInfo", newAfterGameInfo);
		gameState.state.afterGameInfo = newAfterGameInfo;
		playSound(valid ? answerCorrectSound : incorrectAnswerSound);
		submitBlankingAnswer(valid);
	}
	function renderBlankedWords() {

		return (
			<ScrollView style={{ flex: 1 }}>
				<View style={{ flex: 1, flexWrap: "wrap", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
					{
						blankedWords?.length > 0
						?	blankedWords.map((blankedWord, b) => {
								let styles = [buttonContentStyle.buttonContent];
								if (blankedWord.replacedIndex >= 0) {
									styles.push({
										backgroundColor: "lightgrey",
										color: "white"
									});
								}
								return (
									<TouchableOpacity
										key={b}
										style={{ maxHeight: 50, margin: 10 }}
										onPress={() => {
											processBlankedWordClick(blankedWord);
										}}>
											<Text style={styles}>{blankedWord.word}</Text>
									</TouchableOpacity>
								);
							})
						: null
					}
				</View>
			</ScrollView>
		);

	}

	function processBlankedWordClick(blankedWord) {

		if (blankedWord.replacedIndex >= 0) {

			// This word is already selected, now replace its position with the blank space
			const newBlankedStatement = 
				blankedStatement.substring(0, blankedWord.replacedIndex) +
				blankSpace +
				blankedStatement.substring(blankedWord.replacedIndex + blankedWord.word.length);

			// Need to update the replaced indices of the selected words that follow the current word
			const lengthDiff = blankSpace.length - blankedWord.word.length;
			blankedWords.forEach(w => {
				if (w.replacedIndex > blankedWord.replacedIndex) w.replacedIndex += lengthDiff;
			});

			blankedWord.replacedIndex = -1;
			setBlankedStatement(newBlankedStatement);

		} else {

			// Replace the first occurring blank space with the selected word
			const replacedIndex = blankedStatement.indexOf(blankSpace);
			const newBlankedStatement = blankedStatement.replace(blankSpace, blankedWord.word);
			
			// Need to update the replaced indices of the selected words that follow the current word
			const lengthDiff = blankedWord.word.length - blankSpace.length;
			blankedWords.forEach(w => {
				if (w.replacedIndex > replacedIndex) w.replacedIndex += lengthDiff;
			});

			blankedWord.replacedIndex = replacedIndex;
			setBlankedStatement(newBlankedStatement);

		}

		setReadyForSubmit(blankedWords.every(w => w.replacedIndex >= 0));

	}

	function renderQuestionImage() {
		if (currentQuestion === undefined || !currentQuestion.imgUrl || !currentQuestion.imgPath) {
			return null;
		}
		return (
			<View style={styles.questionImageContainer}>
				<Image style={styles.image} source={{ uri: currentQuestion.imgUrl }} />
			</View>
		);
	}

	function crossWordComplete(e) {
		console.log("complete", e);
		setIsReadyToContinue(true);
	}
	function crossWordAnswerCorrect(e) {
		console.log("correct", e);
		setCrossWordCorrect(e);
	}
	async function submitCrossWord() {
		console.log("correct");
		playSound(crossWordCorrect ? answerCorrectSound : incorrectAnswerSound);
		//increment score
		console.log("currentQuestionScore", currentQuestionScore);
		let newScore = crossWordCorrect ? currentQuestionScore + 1 : currentQuestionScore + 0;
		setCurrentQuestionScore(newScore);
		const newUserAnswer = {
			questionText: currentQuestion.questionText,
			questionTypeId: currentQuestion.questionType,
			questionType: "Crossword",
			correctAnswer: currentQuestion.data.map(item => item.value),
			userAnswer: "Not available",
			score: crossWordCorrect ? 1 : 0
		};

		const newUserAnswers = [...userAnswers, newUserAnswer];
		setUserAnswers(newUserAnswers);
				
		const whereToFindAnswer = {
			learningTopicId: currentQuestion.learningTopicId !== undefined ? currentQuestion.learningTopicId : undefined,
			learningSubTopicId: currentQuestion.learningSubTopicId !== undefined ? currentQuestion.learningSubTopicId : undefined,
			whereToFind: currentQuestion.whereToFind !== undefined ? currentQuestion.whereToFind : "",
		};
		const newAfterGameInfo = gameState.state.afterGameInfo;
		newAfterGameInfo.push({
			categoryId: categoryId,
			questionText: currentQuestion.questionText,
			answerText: crossWords.map((crossWord) => crossWord.answer),
			isCorrect: crossWordCorrect ? 1 : 0,
			whereToFindAnswer: whereToFindAnswer,
		});
		console.log("newAfterGameInfo", newAfterGameInfo);
		//delete key guesses from local storage
		// localStorage.removeItem("guesses");

		submitBlankingAnswer(crossWordCorrect);
		setCrossWordData({ across: {}, down: {} });
	}

	return (
		<View style={styles.gameContainer}>
			<Image source={backgroundImages[imageBackgroundIndex]} style={styles.backgroundImage}></Image>
			{
				!isLoading && !isCheckingActiveGameDetails 
				? <>
					<View style={styles.headerContainerStyle}>
						<CountDownTimer
							questionTime={questionTime}
							refresh={refreshTimer}
							setTimeTaken={setTimeTaken}
							timeUp={timeUp}
							isQuestionActive={isQuestionActive}
							timerIsStarted={timerIsStarted}
							timerIsStopped={timerIsStopped}
							shouldStartTimer={gameState.shouldStartTimer}
							shouldStopTimer={gameState.shouldStopTimer}></CountDownTimer>
					</View>
					<View style={styles.iconContrainer}>
						{/* TODO trophy icon. It's not in the standard set: https://wiki.in.tum.de/System/MaterialIcons */}
						<Icon
							name="view-headline"
							size={hp("5%")}
							color="#000"
							onPress={() => {
								setModalDisplay(true);
							}}
							iconStyle={{ opacity: 0.8, width: "100%" }}
						/>
					</View>
					{renderLeaderboardModal()}
					<View style={styles.questionTextContainer}>
						<Text style={styles.questionNumberText}>Question {currentQuestionIndex + 1}</Text>
						<Text style={styles.questionText}>
							{
								currentQuestion !== undefined
								? currentQuestion.questionType !== 4
									? parseQuestionText(currentQuestion.questionText)
									: parseQuestionText(currentQuestion.questionText) + "\n" + tapsLeft + " taps left"
								: LOADING_QUESTION
							}
						</Text>
					</View>
					{currentQuestion ? renderLearningMedia(currentQuestion.mediaLink, currentQuestion.mediaType) : null}
					{renderQuestionImage()}
					{
						currentQuestion?.questionType == 6 && (crossWordLayoutData?.result?.length ?? 0) > 0
						? <ScrollView style={{ flex: 1 }}>
							<View style={{ flex: 1, width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
								<View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
									<CrosswordPuzzle
										crosswordData={crossWordLayoutData?.result ?? []}
										onCrosswordComplete={crossWordComplete}
										onCrosswordCorrect={crossWordAnswerCorrect}
										cols={crossWordLayoutData?.cols ?? 0}
										rows={crossWordLayoutData?.rows ?? 0}
									/>
								</View>
								{/*Area that shows possible answers*/}
								<View style={{ flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
									<View style={{ flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
										<View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
											<Text style={{ fontSize: 20, fontWeight: "bold" }}>Possible answers:</Text>
										</View>
										{
											!currentQuestion?.data
											?	null
											:	<View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
													<Text style={{ fontSize: 20 }}>{currentQuestion.data.map((item) => item?.value).join("   ")}</Text>
												</View>
										}
									</View>
									<View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: 20, marginBottom: 20 }}>
										<Button title="Submit" onPress={submitCrossWord} disabled={!isReadyToContinue} titleStyle={{ color: "#fff", fontSize: 20, borderRadius: 16, }} />
									</View>
								</View>
							</View>
						</ScrollView>
						: null
					}
					{renderAnswerButtons(currentQuestion !== undefined && currentQuestion.questionType !== undefined ? currentQuestion.questionType : 0)}
					{bucketSelectAreas()}
				</> 
				: null 
			}
			{
				questionResult != "unanswered"
				? <QuestionAnsweredBanner result={questionResult} questionScore={currentQuestionScore} questionMaxScore={maxCurrentQuestionScore}></QuestionAnsweredBanner>
				: null
			}
			{
				bannerMessage 
				? <MessageBanner displayMessage={displayMessageBanner} message={bannerMessage} isErrorMessage={!isCheckingActiveGameDetails}></MessageBanner>
				: null
			}
		</View>
	);
}
